import provider from "./config";

export async function getSectors() {
  try {
    const response = await provider.get("/sector");
    return response.data;
  } catch (err) {
    return { error: true, message: err.message };
  }
}

export async function getSectorName(id) {
  try {
    const response = await provider.get(`/sector/${id}`);
    const { name } = response.data;
    return name;
  } catch (err) {
    return { error: true, message: err.message };
  }
}

export async function getCategoryName(id) {
  try {
    const response = await provider.get(`/category/${id}`);
    const { name } = response.data;
    return name;
  } catch (err) {
    return { error: true, message: err.message };
  }
}

export async function getSubcategoryName(id) {
  try {
    const response = await provider.get(`/subcategory/${id}`);
    const { name } = response.data;
    return name;
  } catch (err) {
    return { error: true, message: err.message };
  }
}

export async function getProduct(id) {
  try {
    const response = await provider.get(
      `/product?product_base=${id}&is_active=true`
    );
    return response.data;
  } catch (err) {
    return { error: true, message: err.message };
  }
}

export async function getProductVariation(id) {
  try {
    const response = await provider.get(
      `/product/${id}`
    );
    return response.data;
  } catch (err) {
    return { error: true, message: err.message };
  }
}

export async function getProductList(
  sector = null,
  category = null,
  subcategory = null,
  filters = null,
  orderBy = "-created_at",
  page = 1,
  search = "",
  highlight = false
) {
  let url = `/product/list?page=${page}&name=${search}&is_active=true`;

  if (highlight) url += `&highlight=${highlight}`;
  if (sector) url += `&sector=${sector}`;
  if (category) url += `&category=${category}`;
  if (subcategory) url += `&subcategory=${subcategory}`;
  if (orderBy) url += `&order_by=${orderBy}`;

  if (filters) {
    let hasFilters = false;
    const filtersValues = [];

    Object.keys(filters).map((key) => {
      if (filters[key] !== "Todos") {
        hasFilters = true;
        filtersValues.push(filters[key]);
      }
    });

    if (hasFilters) {
      url += "&attrs=";

      filtersValues.map((filter, index) => {
        if (index !== filtersValues.length - 1) {
          url += `${filter},`;
        } else {
          url += filter;
        }
      });
    }
  }

  try {
    const response = await provider.get(url);
    return response.data;
  } catch (err) {
    return { error: true, message: err.message };
  }
}

export async function getFilters(
  sector = 1,
  category = null,
  subcategory = null
) {
  let url = `/property/filter?sector=${sector}`;

  if (category) {
    url += `&category=${category}`;
  }

  if (subcategory) {
    url += `&subcategory=${subcategory}`;
  }

  try {
    const response = await provider.get(url);
    return response.data;
  } catch (err) {
    return { error: true, message: err.message };
  }
}

export async function evaluateProduct(payload) {
  try {
    const response = await provider.post("/evaluation/", payload);
    return response.data;
  } catch (err) {
    return { error: true, message: err.message };
  }
}

export async function getEvaluations(id, page = 1) {
  try {
    const response = await provider.get(
      `/evaluation?evaluated=${id}&page=${page}`
    );
    return response.data;
  } catch (err) {
    return { error: true, message: err.message };
  }
}
