import React, { createContext, useState, useEffect, useContext } from "react";
import ReactPixel from 'react-facebook-pixel';

import createNotification from "../utils/notification";
import { getStore } from "../providers/storeProvider";

const StoreContext = createContext({
  store: null,
});

export const StoreProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [store, setStore] = useState({});
  const [pixelReady, setPixelReady] = useState(false);

  useEffect(() => {
    async function getStoreData() {
      const result = await getStore();

      if (result.error) {
        console.log("Sem informações da loja");
        return createNotification(
          "error",
          "Erro",
          "Ocorreu um erro ao tentar carregar as informações da loja"
        );
      } else {
        initFacebookPixel(result);
      }
      //adding favicon and title
      await new Promise((resolve, reject) => {
        let link =
          document.querySelector('link[rel="shortcut icon"]') ||
          document.querySelector('link[rel="icon"]');

        if (!link) {
          link = document.createElement("link");
          link.id = "favicon";
          link.rel = "shortcut icon";
          document.head.appendChild(link);
        }

        link.href = result.favicon;

        let htmlTitle = document.querySelector("title");

        if (!htmlTitle) {
          htmlTitle = document.createElement("title");
          htmlTitle.innerHTML = result.name;
          document.head.appendChild(htmlTitle);
        }

        if(process.env.REACT_APP_API === "https://api.acquamenina.com/api/v1"){
          const tagScript = document.createElement('script');
          tagScript.type = 'text/javascript';
          // tagScript.innerHTML = "!function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,document,'script','https://connect.facebook.net/en_US/fbevents.js'); fbq('init', '321853029550679'); fbq('track', 'PageView');";
          
          document.head.appendChild(tagScript);
        }


        // const googleAdsCode = "AW-944818882";

        // if(googleAdsCode !== ""){
          // const adsScript = document.createElement("script");

          // adsScript.src = `https://www.googletagmanager.com/gtag/js?id=${googleAdsCode}`;
          // adsScript.async = true;

          // document.head.appendChild(adsScript);

          // const gtagScript = document.createElement('script');
          // gtagScript.type = 'text/javascript';
          // gtagScript.innerHTML = "window.dataLayer = window.dataLayer || []; function gtag() { dataLayer.push(arguments); } gtag('js', new Date());";
          
          // document.head.appendChild(gtagScript);
        // }

        resolve();
      });

      setLoading(false);
      setStore(result);
    }

    getStoreData();
  }, []);

  function initFacebookPixel (storeInfor) {
    console.log(storeInfor);
    const { facebook_pixel } = storeInfor;

    if(facebook_pixel) {
      const options = {
        autoConfig: true, 
        debug: false,
      };
      ReactPixel.init(facebook_pixel, {}, options);
      ReactPixel.pageView();
      setPixelReady(true);
    }
  }

  return (
    <StoreContext.Provider value={{ store, pixelReady }}>
      {!loading && children}
    </StoreContext.Provider>
  );
};

export function useStore() {
  const context = useContext(StoreContext);

  return context;
}
