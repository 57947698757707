import React from "react";
import { Card, Image, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import fadeUp from "../../animations/fadeUp";
import ErrorImage from "../../assets/images/error-image.png";

import "./styles.css";

export default function ProductCard({ product, style, imageStyle }) {
  switch (parseInt(process.env.REACT_APP_CARD_LAYOUT)) {
    default:
      return (
        <FirstCard product={product} style={style} imageStyle={imageStyle} />
      );
    case 1:
      return (
        <FirstCard product={product} style={style} imageStyle={imageStyle} />
      );
    case 2:
      return (
        <SecondCard product={product} style={style} imageStyle={imageStyle} />
      );
  }
}

function FirstCard({ product, style, imageStyle }) {
  return (
    <Card
      as={Link}
      to={`/produto/${product.product_base.id}`}
      style={{ ...style }}
    >
      <Image
        alt={product.product_base.product}
        style={{ ...imageStyle }}
        className="card-image"
        src={product.product_base.image}
        onError={(e) =>
          (e.target.src = ErrorImage)
        }
      />
      <Card.Content>
        <Card.Header className="primary-card-header">
          <p>{product.product_base.product}</p>
        </Card.Header>
        <Card.Description as="h2" className="product-card-description">
          {product.promotional_value ? (
            <div className="card-promotional-price">
              <p>
                R$ {parseFloat(product.value).toFixed(2)}
              </p>
              <span>
                R$ {parseFloat(product.promotional_value).toFixed(2)}
              </span>
            </div>
          ): (
            <p className="price">R$ {parseFloat(product.value).toFixed(2)}</p>
          )}
        </Card.Description>
        <Card.Meta>
          <span className="date">
            {product.installment && (
              <>
                {product.installment}x de R$
                {(product.value / parseInt(product.installment)).toFixed(2)}
              </>
            )}
          </span>
        </Card.Meta>
      </Card.Content>
    </Card>
  );
}

function SecondCard({ product, style, imageStyle }) {
  return (
    <Card
      as={motion.div}
      initial="fadeup"
      animate="normal"
      exit="exit"
      variants={fadeUp}
      style={{ ...style }}
    >
      <Image
        alt={product.product_base.product}
        style={{ ...imageStyle }}
        className="card-image"
        src={product.product_base.image}
        onError={(e) =>
          (e.target.src = ErrorImage)
        }
      />
      <Card.Content>
        <Card.Header className="secondary-card-header">
          {product.product_base.product.substring(0, 20).toUpperCase()}
          {product.product_base.product.length > 20 && "..."}
        </Card.Header>
        <Card.Description className=".product-card-description secondary-card-description">
          {product.promotional_value ? (
            <div className="card-promotional-price">
              <p>
                R$ {parseFloat(product.value).toFixed(2)}
              </p>
              <span>
                R$ {parseFloat(product.promotional_value).toFixed(2)}
              </span>
            </div>
          ): (
            <p className="price">R$ {parseFloat(product.value).toFixed(2)}</p>
          )}
          {parseInt(product.installment) > 1 ? (
            <p className="installment">
              ou{" "}
              {product.installment && (
                <>
                  <span>{product.installment}x</span> de{" "}
                  <span>
                    R$
                    {(product.value / parseInt(product.installment)).toFixed(2)}
                  </span>
                </>
              )}
            </p>
          ) : (
            <p className="installment" />
          )}
          <Button primary as={Link} to={`/produto/${product.product_base.id}`}>
            Ver produto
          </Button>
        </Card.Description>
      </Card.Content>
    </Card>
  );
}
