import provider from "./config";

export async function getStore() {
  try {
    const response = await provider.get("/store/1");
    return response.data;
  } catch (err) {
    return { error: true, message: err.message };
  }
}

export async function sendContactMessage(payload) {
  try {
    const response = await provider.post("/support/", payload);
    return response.data;
  } catch (err) {
    return { error: true, message: err.message };
  }
}
