import provider from "./config";

export async function getBanners() {
  try {
    const response = await provider.get("/banner?is_active=true");
    return response.data;
  } catch (err) {
    return { error: true, message: err.message };
  }
}

export async function getFAQ() {
  try {
    const response = await provider.get("/faq");
    return response.data;
  } catch (err) {
    return { error: true, message: err.message };
  }
}
