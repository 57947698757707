import provider from "./config";

export async function checkEmail(email) {
  try {
    const response = await provider.get("/user/validate/", {
      params: {
        email
      }
    });
    return response.data;
  } catch (err) {
    return { error: true, message: err.message };
  }
}

export async function signIn(payload) {
  try {
    const response = await provider.post("/authentication/", payload);
    return response.data;
  } catch (err) {
    return { error: true, message: err.message };
  }
}

export async function signUp(payload) {
  try {
    const response = await provider.post("/user/", payload);
    return response.data;
  } catch (err) {

    if(err.response && err.response.data){
      if(err.response.data.cpf_cnpj){
        return { error: true, message: "Este CPF já está cadastrado no sistema" };
      }
      if(err.response.data.phone){
        return { error: true, message: "Este telefone já está cadastrado no sistema" };
      }
    }

    return { error: true, message: "Ocorreu um erro ao tentar fazer o seu cadastro" };
  }
}

export async function updateProfile(id, payload, token) {
  try {
    const response = await provider.patch(`/user/${id}/`, payload, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (err) {
    return { error: true, message: err.message };
  }
}

export async function getUserOrders(id, token) {
  try {
    const response = await provider.get(`/order?user=${id}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (err) {
    return { error: true, message: err.message };
  }
}